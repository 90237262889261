
import { Options, Vue } from "vue-class-component";
import { settingsModule, proportionsModule } from "@/store";
import {
  ActiveInterface,
  Proportions,
  ProportionsInterface,
  BaseSettingsItem,
} from "@/types";
import { copy } from "@/utils";
import {
  calculatePropertyUnit,
  parseSentenceToNumber,
  calculateHeightFromProperty,
} from "./calculations";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import Loader from "@/components/ui/BaseLoader/index.vue";

@Options({
  name: "PropertiesList",
  components: {
    Loader,
    SvgIcon,
  },
  watch: {
    propertyValue(val) {
      if (val) {
        const unit = this.getUnit;
        const proportion = this.getProportions[this.isActive];
        const property = parseSentenceToNumber(val, unit);
        const calculatedHeight = calculateHeightFromProperty({
          property,
          unit,
          proportion: proportion[Proportions.Number],
        });

        settingsModule.Height(calculatedHeight);
        this.emitter.emit("height-change", calculatedHeight);
      }
    },
  },
})
export default class PropertiesList extends Vue {
  $refs!: {
    list: HTMLFormElement;
  };

  private isActive = -1;
  private isEditable: Array<boolean> = [];
  private propertyValue = "0";

  get getActive(): ActiveInterface {
    const shortList = ["newborn", "infant", "young_child", "child"];

    if (settingsModule.getActive) {
      const active = copy(settingsModule.getActive);
      const part = active.part?.name as string;
      const type = active.type?.name as string;

      if (part === "head") {
        const excludeProperty = shortList.includes(type)
          ? ["length of eye and space between eyes"]
          : ["length of eye", "space between eyes"];

        active.properties = active.properties?.filter(
          (p: BaseSettingsItem) => !excludeProperty.includes(p.title || "")
        );
      }

      return active;
    }

    return {};
  }

  private get getUnits(): Array<string> {
    return settingsModule.getUnits;
  }

  private get getActiveUnit(): number {
    return settingsModule.getUnit;
  }

  public get getUnit(): string {
    return this.getUnits[this.getActiveUnit] as string;
  }

  private get getHeight(): number {
    return settingsModule.getHeight || 0;
  }

  private get isLoading(): boolean {
    return settingsModule.getGlobalLoader || proportionsModule.getGlobalLoader;
  }

  private get getProportions(): Array<[number, string]> {
    if (this.isLoading) {
      return [];
    }

    const active = this.getActive;
    const proportionsPlain = copy(proportionsModule.getProportions);
    const gKey = active!.gender!.name as keyof ProportionsInterface;
    const tKey = active!.type!.name as keyof ProportionsInterface;
    const pKey = active!.part!.name as keyof ProportionsInterface;

    return proportionsPlain[gKey][tKey][pKey];
  }

  private getPropertyTitle(title: string, index: number): string {
    const proportions = this.getProportions[index];

    if (!proportions || !proportions[Proportions.String]) {
      return title;
    }

    return `${title} = ${proportions[Proportions.String]}`;
  }

  private calculatePropertyValue(index: number): number | string {
    const proportion = this.getProportions[index];

    if (!proportion) {
      return 0;
    }

    return calculatePropertyUnit(
      this.getHeight,
      proportion[Proportions.Number],
      this.getUnit
    );
  }

  private setActive(index: number): void {
    this.isActive = index;
    this.emitter.emit("properties-change", index);
  }

  private getPropertyValue(event: KeyboardEvent): void {
    this.propertyValue = (event.target as HTMLInputElement).value;
  }

  private onDblClickAction(e: any, i: number, state: boolean): void {
    if (!this.isValidProportion(i)) return;

    this.isEditable[i] = state;

    if (state) {
      setTimeout(() => {
        const inputEl = this.$refs.list.children[i].querySelector("input");
        inputEl && inputEl.focus();
      }, 200);
    }
  }

  private onEnterAction(e: any, i: number): void {
    if (e.keyCode === 13) {
      this.isEditable[i] = false;
    }
  }

  private isValidProportion(index: number): boolean {
    const proportion = this.getProportions[index];

    if (!proportion) {
      return false;
    }

    return proportion[Proportions.Number] > 0;
  }

  created(): void {
    proportionsModule.Load();
  }

  mounted(): void {
    this.emitter.on("properties-filter-change", (reset: boolean) => {
      if (reset) {
        this.isActive = -1;
      } else {
        this.isActive > -1 &&
          setTimeout(() => this.setActive(this.isActive), 500);
      }
    });
  }
}
