const ONE_CENTIMETER_IN_FEET = 0.3937008;
const CENTIMETERS_TO_INCHES = 2.54;
const FOOT_IN_ONE_CENTIMETER = 30.48;
const INCHES = 12;

const round = (value: number): number => {
    return Math.ceil(value * 10) / 10;
};

const convertFeetToCentimeters = (foot: number): number => {
    return round(foot * FOOT_IN_ONE_CENTIMETER);
};

const convertCentimeterToFeet = (centimeters: number): string => {
    const calculatedInches = centimeters * ONE_CENTIMETER_IN_FEET;

    if (calculatedInches < INCHES) {
        return `${round(calculatedInches)}"`;
    }

    const feet = calculatedInches / INCHES;
    const remainingInches = Math.round(calculatedInches % INCHES);

    return `${Math.floor(round(feet))}' ${round(remainingInches)}"`;
};

const calculate = (height: number, proportion: number): number => {
    return round((height * proportion) / 300);
};

export const inCentimeters = (
    height: number,
    proportion: number
): { [key: string]: number } => {
    return {
        cm: calculate(height, proportion),
    };
};

export const inMeters = (
    height: number,
    proportion: number
): { [key: string]: number } => {
    return {
        m: calculate(height, proportion),
    };
};

export const inFeet = (
    height: number,
    proportion: number
): { [key: string]: string } => {
    const feetInCentimeters: number = calculate(convertFeetToCentimeters(height), proportion);

    return {
        ft: convertCentimeterToFeet(feetInCentimeters),
    };
};

export const calculatePropertyUnit = (
    height: number,
    proportion: number,
    format: string
): number | string => {
    const measurements = [
        inFeet(height, proportion),
        inCentimeters(height, proportion),
        inMeters(height, proportion),
    ];

    for (const measurement of measurements) {
        if (measurement[format as string]) {
            return measurement[format as string];
        }
    }

    return 0;
};

export const calculateHeightFromProperty = ({
    property,
    unit,
    proportion
}: {
    property: any,
    unit: string,
    proportion: number
}) => {
    if (unit === 'ft') {
        const [feet, inch] = property.feetInchArray;
        const totalInches = (parseInt(feet) * INCHES) + parseInt(inch || '0');
        const totalCentimeters = totalInches * CENTIMETERS_TO_INCHES;

        return Math.floor(totalCentimeters / proportion * 300 / FOOT_IN_ONE_CENTIMETER);
    }

    return Math.floor(property.value / proportion * 300);
}

export const parseSentenceToNumber = (sentence: string, unit: string) => {
    const reg = /[+-]?\d+\.?\d*/g;
    const matches = sentence.match(reg);

    if (unit === 'ft') {
        const [feet, inch]: any = sentence.match(reg) || [];

        if (!inch) {
            return {
                value: `${feet}'`,
                feetInchArray: [feet]
            };
        }

        return {
            value: `${feet}' ${inch}"`,
            feetInchArray: [feet, inch]
        };
    }

    return {
        value: matches ? matches[0] : '',
        feetInchArray: matches || 0
    };
}
