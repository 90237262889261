import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cad454a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "list",
  ref: "list"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onDblclick"]
const _hoisted_4 = ["onKeyup", "onDblclick", "onBlur"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_perfect_scrollbar, {
        key: 1,
        options: { wheelPropagation: false }
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getActive.properties, (property, i) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["list-item", { 'list-item--active': i === this.isActive }]),
                key: i,
                onClick: ($event: any) => (_ctx.setActive(i))
              }, [
                _createVNode(_component_svg_icon, {
                  class: "icon circle icon--small icon--margin-right",
                  src: `icons/circle-ico.svg`
                }, null, 8, ["src"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.getPropertyTitle(property.title, i)), 1),
                (!_ctx.isEditable[i])
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "value",
                      onDblclick: ($event: any) => (_ctx.onDblClickAction($event, i, true))
                    }, _toDisplayString(_ctx.calculatePropertyValue(i)) + " " + _toDisplayString(_ctx.getUnit !== "ft" ? _ctx.getUnit : ""), 41, _hoisted_3))
                  : (_openBlock(), _createElementBlock("input", {
                      key: 1,
                      type: "text",
                      name: "property_value",
                      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getPropertyValue($event))),
                      onKeyup: ($event: any) => (_ctx.onEnterAction($event, i)),
                      onDblclick: ($event: any) => (_ctx.isEditable[i] = false),
                      onBlur: ($event: any) => (_ctx.isEditable[i] = false)
                    }, null, 40, _hoisted_4))
              ], 10, _hoisted_2))
            }), 128))
          ], 512)
        ]),
        _: 1
      }))
}